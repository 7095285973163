<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit {{adminData.username}}</h4>
                        <form @submit.prevent="updateUser">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Name"
                                                v-model="adminData.name"

                                        />

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Username</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Username"
                                                v-model="adminData.username"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Country</label>
                                        <multiselect  :preselect-first="true" label="country_name" v-model="adminData.country" :options="options" :multiple="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                v-model="adminData.email"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Password</label>
                                    <input

                                            type="password"
                                            class="form-control"
                                            placeholder="Password"
                                    />
                                </div>
                            </div>
                        </div>
                            <button class="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
                <!-- end card -->
            </div>
            <div class="lodader__content" v-if="loader">
                <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get, axios_put} from "../../../helpers/helper";
    import Multiselect from "vue-multiselect";


    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Edit Admin",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, Multiselect, PageHeader },
        data() {
            return {
                country:"",
                adminData:null,
                loader: false,
                userData:null,
                options:[],
                title: "Edit Admin",
                items: [
                    {
                        text: "View All Admins",
                        href: "/admins"
                    },
                    {
                        text: "Edit Admin",
                        active: true
                    }
                ],
            };
        },
        validations: {

        },
        methods: {
            updateUser(){
                this.loader = true
                axios_put("/user/"+this.id,{...this.adminData},
                    (d)=>{
                        console.log(d);
                        window.location.href = '/admins'
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    })

            },
        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id)
                axios_get("/user/"+this.id,{},(d)=>{
                        console.log(d);
                        this.adminData=d;
                        console.log(this.adminData);
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
            this.options=JSON.parse(localStorage.getItem('country')),
            console.log(this.options)
        },


    };

</script>

<style scoped>
.lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>